import React from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { Spinner } from 'react-bootstrap'

function BottomButton({ below, className, href, gtm, transparent, nonSticky, noSideMargin, disabled, handleClick, pending, text }) {
  const { query } = useRouter()
  let wrapperClassNames = nonSticky ? 'non-sticky' : ''
  wrapperClassNames = `${wrapperClassNames}` + className

  let hrefLink = href
  // persist crew or refer link across page transitions
  if (query.c) {
    hrefLink = `${hrefLink}?c=${query.c}`
  } else if (query.r) {
    hrefLink = `${hrefLink}?r=${query.r}`
  }

  return (
    <div id="bottom-button-wrapper" className={wrapperClassNames}>
      <Link href={hrefLink}>
        <button
          id={gtm}
          className={`btn btn-primary fluid ${disabled ? 'disabled' : ''} ${transparent ? 'transparent' : ''}`}
          onClick={handleClick}
          disabled={disabled}
        >
          {' '}
          {pending ? (
            <Spinner animation="border" role="status" size="sm">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            text
          )}
        </button>
      </Link>
      {below && below()}
    </div>
  )
}

BottomButton.defaultProps = {
  below: null,
  className: '',
  disabled: false,
  handleClick: () => {},
  href: '',
  gtm: '',
  nonSticky: false,
  noSideMargin: false,
  pending: false,
  text: '',
  transparent: false,
}

BottomButton.propTypes = {
  below: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func, // not required because of possible disabled state
  href: PropTypes.string,
  gtm: PropTypes.string,
  nonSticky: PropTypes.bool,
  noSideMargin: PropTypes.bool,
  pending: PropTypes.bool,
  text: PropTypes.string, // not required for pending state
  transparent: PropTypes.bool,
}

export default BottomButton
